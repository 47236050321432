export const normalizeValue = (value, minValue = 0, maxValue = 100) => {
  if (value <= minValue) {
    return minValue
  }

  if (value >= maxValue) {
    return maxValue
  }

  return value
}

export const formatAsCurrency = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (parts.length > 1) {
    if (parts[1].length < 2) {
      return '$ ' + parts.join(".") + '0';
    } else {
      return '$ ' + parts.join(".");
    }
  } else {
    return '$ ' + parts[0] + '.00';
  }
}